$color-text-default: white;
$bg-body: black;

$color-teal: rgba(4,255,254,1);
$color-pink: rgba(250,4,255,1);
$color-green: rgba(62,255,83,1);

@font-face {
  font-family: system;
  font-style: normal;
  font-weight: 300;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}

%rainbow-text {
  background:
    linear-gradient(
      90deg,
      $color-teal 0%,
      $color-green 33%,
      $color-pink 66%,
      $color-teal 100%,
    );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  // text-fill-color: transparent;
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 4px transparent;

  animation: shine 4.25s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}

html,
body {
  height: 100%;
  margin: 0;
  // font-family: 'Courier New', Courier, monospace;
  // font-family: Frutiger, "Frutiger Linotype", Univers, Calibri, "Gill Sans", "Gill Sans MT", "Myriad Pro", Myriad, "DejaVu Sans Condensed", "Liberation Sans", "Nimbus Sans L", Tahoma, Geneva, "Helvetica Neue", Helvetica, Arial, sans-serif;
  // font-family: "system";
  // font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
  font-family: Arial, sans-serif;
}

// A general comment that should be
// stripped out in production
body {
  color: $color-text-default;
  background-color: $bg-body;
}

header {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
}

.site-nav {
  box-sizing: border-box;

  width: 100%;
  padding: 5vw;
  font-size: 10vw;
  font-weight: 400;

  @media screen and (min-width: 600px) {

    font-size: 5vw;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    margin: 0;
  }

  a {
    @extend %rainbow-text;

    text-transform: uppercase;
    text-decoration: none;
    padding: 3px;

    &:focus {
      text-decoration: underline;
    }
  }
}

a {
  color: $color-text-default;
}

main {


}

img {
  max-width: 100%;
  height: auto;
}

.video-wrapper {
  display: flex;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  @media screen and (min-width: 768px) {
    // top: -10vh;
  }

  @media screen and (min-width: 1024px) and (min-height: 768px) and (orientation:landscape) {
    // top: -20vh;
  }

  @media screen and (min-width: 1200px) {
    // padding: 10vh 0 0;
    top: 10vh;
  }

  video  {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    margin: 0 auto;

    // @media screen and (min-width: 600px) {
    //   object-fit: contain;
    // }
  }
}

.icon {
  display: inline-block;
  height: 1em;
  width: 1em;
  margin-right: 0.25em;
  vertical-align: middle;
  font-size: 1.5em;
  fill: currentColor;
  pointer-events: none;
}

.social-links {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

  .social-links__list {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    li {
      margin: 1rem;
    }

    a {

    }

    svg {
      path {
        fill: $color-pink;
      }
    }

    li:nth-of-type(2) a svg path {
      fill: $color-teal;
    }

    li:nth-of-type(3) a svg path {
      fill: $color-green;
    }
  }

.body-404 {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
}
